const CLASS_FORM = 'm-newsletter__form'
const CLASS_FORM_US = 'm-newsletter-us__form'
const CLASS_FORM_US_MOBILE = 'm-newsletter-us__form.mobile'
const CLASS_SUCCESS = 'm-newsletter--success'
const CLASS_ERROR = 'm-newsletter--error'
const CLASS_DISMISS = 'm-newsletter-us__cross-icon'
const NAME_INPUT_EMAIL = 'newsletter-email'
const XHR_ENDPOINT = '/nex/modules/newsletter_signup/v1?isXHR=true&m_mode=json'
const COOKIE_NAME = 'isNewsLetterEnable'
const CLASS_SIGNED_UP = 'm-newsletter--signed-up'
const CLASS_CONTAINER = 'm-newsletter__container'
const INVALID_MESSAGE_SELECTOR = 'invalid-message'
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export default (() => {
  class NewsLetterController {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]

      if (!this.elems.length) {
        return
      }
      this.utils = window.NEXUtils || {}

      this.elems.forEach(elem => {
        this.init(elem)
      })
    }

    init(elem) {
      if (elem.classList.contains(CLASS_SIGNED_UP)) {
        // is already signed up
        this.updateContainerClass(elem)
        return
      }
      const { dataset: { slot = '', rendertype = '' } = {} } = elem
      const signupFormUs = elem.querySelector(`.${CLASS_FORM_US}`)
      const signupFormUsMobile = elem.querySelector(`.${CLASS_FORM_US_MOBILE}`)

      this.isUSnewsletter = rendertype === 'newsletter_us'

      let signupForm =
        elem.querySelector(`.${CLASS_FORM}`) ||
        (window.innerWidth > 600 ? signupFormUs : signupFormUsMobile)

      if (slot === 'rr') signupForm = signupFormUsMobile

      const dismissIcon = elem.querySelector(`.${CLASS_DISMISS}`)
      dismissIcon?.addEventListener('click', () => elem.classList.add('m-newsletter-us--hide'))

      signupForm?.addEventListener('submit', e => this.submitHandler(e, elem))

      // add event listener for input field
      if (this.isUSnewsletter) {
        const emailInput = signupForm.querySelector(`input[name="${NAME_INPUT_EMAIL}"]`)
        this.submitButton = signupForm.querySelector('.m-newsletter-us__form--submit')
        this.invalidMessage = signupForm.querySelector(`.${INVALID_MESSAGE_SELECTOR}`)
        if (emailInput) {
          emailInput.addEventListener('input', e => this.inputHandler(e, signupForm))
        }
      }
    }

    updateContainerClass(elem) {
      const container = elem.closest(`.${CLASS_CONTAINER}`)
      container?.classList.add(CLASS_SIGNED_UP)
    }

    isValidEmail(email) {
      return EMAIL_REGEX.test(email)
    }

    inputHandler(e, signupForm) {
      const email = e.target.value
      if (email.length > 0 && this.isValidEmail(email)) {
        this.submitButton.classList.add('valid')
      } else {
        this.submitButton.classList.remove('valid')

        if (signupForm.classList.contains(CLASS_ERROR)) {
          signupForm.classList.remove(CLASS_ERROR)
          this.invalidMessage?.classList.add('hidden')
          this.submitButton.disabled = false
        }
      }
    }

    submitHandler(e, elem) {
      e.preventDefault()
      const form = e.currentTarget

      if (this.isUSnewsletter) {
        if (!this.isValidEmail(form[NAME_INPUT_EMAIL]?.value)) {
          this.setResponseUI(form, false)
          return
        }
      }

      const requestData = {
        method: form.method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: form[NAME_INPUT_EMAIL]?.value
        })
      }

      const NL_ENDPOINT = this.isUSnewsletter
        ? `${XHR_ENDPOINT}&renderType=newsletter_us`
        : XHR_ENDPOINT

      fetch(NL_ENDPOINT, requestData)
        .then(response => response.json())
        .then(responseData => {
          const {
            data: {
              response: { success }
            }
          } = responseData

          if (success) {
            this.setResponseUI(elem, true)
            this.setNewsletterCookie()
          } else {
            this.setResponseUI(elem, false)
          }
        })
        .catch(err => {
          this.setResponseUI(elem, false)
        })
    }

    setResponseUI(elem, isSuccess) {
      if (isSuccess) {
        elem.classList.remove(CLASS_ERROR)
        elem.classList.add(CLASS_SUCCESS)
        this.invalidMessage?.classList.add('hidden')
        if (this.submitButton) this.submitButton.disabled = false
      } else {
        elem.classList.remove(CLASS_SUCCESS)
        elem.classList.add(CLASS_ERROR)
        this.invalidMessage?.classList.remove('hidden')
        if (this.submitButton) this.submitButton.disabled = true
      }
    }

    setNewsletterCookie() {
      const currentTime = new Date().getTime()
      this.utils.setCookie(COOKIE_NAME, currentTime, 3)
    }
  }

  return NewsLetterController
})()
